import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import FlatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import { getProjectLink } from "@/app/helpers";
import Modal from "@/views/modal/Modal.vue";
import VersionSelect from "@/views/common/VersionSelect.vue";
import IconExternalLink from "@/icons/external-link-square-alt-solid.svg";
import IconEdit from "@/icons/pen-solid.svg";

export default {
    name: "ProjectSettings",
    props: ["project", "versionsAll"],
    components: {
        Multiselect,
        FlatPickr,
        Modal,
        IconExternalLink,
        IconEdit,
        VersionSelect,
    },
    data() {
        return {
            billingMarkers: [],
            customer: this.project.customer_id ?
                this.customer = {
                    id: this.project.customer_id,
                    name: this.project.customer_name,
                } : null,
            customers: [],
            loadingCustomers: false,
            dateConfig: { locale: French, wrap: true },
            lockedUntil: this.project.locked_until,
            defaultVersion: this.versionsAll.find(v => v.id === this.project.default_version_id),
            descriptionInit: '',
            description: '',
            workGeoRestriction: this.project.work_geo_restriction,
        };
    },
    computed: {
        ...mapGetters({}),
        redmineLink() {
            return getProjectLink(this.project.id);
        },
        versions() {
            return this.versionsAll.filter(
                (ver) =>
                    ver.id != -1 &&
                    (ver.status !== "closed" || ver === this.defaultVersion)
            );
        },
        descriptionChanged() {
            return this.description !== this.descriptionInit;
        },
    },
    methods: {
        canDismissDescriptionEdit() {
            if (!this.descriptionChanged) return true;
            return window.confirm("Billing note has been modified but not submitted\nAre you sure you want to forget it unsaved ?");
        },
        searchCustomers(search) {
            if (this.loadingCustomers) return;
            if (search.length < 2) return (this.customers = []);
            this.loadingCustomers = true;
            this.$store.dispatch("Credit/list/searchCustomers", search)
                .then((customers) => {
                    this.customers = customers;
                    this.loadingCustomers = false;
                });
        },
        editVersions() {
            if (!this.canDismissDescriptionEdit()) return;
            this.$emit("edit-versions");
            this.$emit("close");
        },
        editMembers() {
            if (!this.canDismissDescriptionEdit()) return;
            this.$emit("edit-members");
            this.$emit("close");
        },
        updateDescription() {
            this.$store.dispatch("Project/edit/update", {
                projectId: this.project.id,
                project: { description: this.description },
            });
        },
        updateCustomer() {
            const customerId = this.customer ? this.customer.id : null;
            const customerName = this.customer ? this.customer.name : null;
            this.$store.dispatch("Project/edit/setExtra", {
                projectId: this.project.id,
                project: { customerId },
            }).then(() => {
                this.project.customer_id = customerId;
                this.project.customer_name = customerName;
            });
        },
        updateLockedUntil() {
            this.$store.dispatch("Project/edit/setExtra", {
                projectId: this.project.id,
                project: { lockedUntil: this.lockedUntil },
            }).then(() => {
                this.project.locked_until = this.lockedUntil;
            });
        },
        updateDefaultVersion() {
            const defaultVersionId = this.defaultVersion ? this.defaultVersion.id : null;
            this.$store.dispatch("Project/edit/update", {
                projectId: this.project.id,
                project: { default_version_id: defaultVersionId },
            }).then(() => {
                this.project.default_version_id = defaultVersionId;
            });
        },
        updateWorkGeoRestriction() {
            this.$store.dispatch("Project/edit/setExtra", {
                projectId: this.project.id,
                project: { workGeoRestriction: this.workGeoRestriction },
            }).then(() => {
                this.project.work_geo_restriction = this.workGeoRestriction;
            });
        },
        close() {
            if (!this.canDismissDescriptionEdit()) return;
            this.$emit("close");
        },
    },
    watch: {
        customer() {
            this.updateCustomer();
        },
        workGeoRestriction() {
            this.updateWorkGeoRestriction();
        },
        defaultVersion() {
            this.updateDefaultVersion();
        },
        lockedUntil() {
            this.updateLockedUntil();
        },
    },
    mounted() {
        if (this.project.customer_id) this.customers = [this.customer];
        this.$store
            .dispatch("Project/list/getBillingTimeline", this.project.id)
            .then((billingMarkers) => {
                this.billingMarkers = billingMarkers;
            });
        this.$store
            .dispatch("Project/list/getDetail", this.project.id)
            .then((detail) => {
                this.descriptionInit = detail.description;
                this.description = detail.description;
            });
    },
};
