

















import { elementOffsetCoords } from "../tree-helpers";

export default {
  name: "TreeNodeStatus",
  props: ["cell"],
  data() {
    return {
      originalDoneRatio: 0,
      submitting: false,
    };
  },
  computed: {
    style() {
      const cellOffsetCoords = elementOffsetCoords(this.cell.element);
      return {
        backgroundColor: "white",
        padding: "5px 8px 0",
        top: `${cellOffsetCoords.top}px`,
        left: `${cellOffsetCoords.right - 290}px`,
        width: "240px",
      };
    },
    doneRatio() {
      return this.cell.issue.agg_done_ratio;
    }
  },
  methods: {
    init() {
      this.originalDoneRatio = this.doneRatio;
    },
    submit(e) {
      if (parseInt(this.doneRatio) === this.originalDoneRatio) return this.close();
      if (this.submitting) return;
      this.submitting = true;
      const vm = this;
      this.$store
        .dispatch("Issue/edit/update", {
          issueId: this.cell.issue.id,
          issue: { done_ratio: this.doneRatio },
        })
        .then(() => {
          vm.$emit("submitted");
          vm.close();
        });
    },
    close(e) {
      this.cell.issue.agg_done_ratio = this.originalDoneRatio;
      this.$emit("close");
    },
  },
  mounted() {
    this.init();
    this.$nextTick(() => {
      this.$refs.input.focus();
    });
  },
};
