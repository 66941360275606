import { mapGetters } from 'vuex';


export default (routeName) => {
    return {
        props: {
            bookmark: null,
        },
        data() {
            return {
                bookmarkLoading: null,
            };
        },
        computed: {
            // @ts-ignore
            ...mapGetters({
                bookmarkSaving: 'Resource/list/bookmarkSaving',
                bookmarks: 'Resource/list/bookmarks',
                user: 'Resource/auth/user',
            }),
        },
        watch: {
            bookmarkSaving(label) {
                if (this.$route.name !== routeName) return;
                const filters = this.getBookmark();
                if (!filters) return;
                const bookmarks = JSON.stringify(this.bookmarks.concat({ label, to: { name: routeName, params: { bookmark: filters } } }));
                this.$store.dispatch('Resource/edit/setExtra', { userId: this.user.id, user: { bookmarks } }).then(() => {
                    this.$store.dispatch('Resource/list/getBookmarks');
                });
            },
            bookmark() {
                this.loadBookmark();
            },
        },
    };
};
