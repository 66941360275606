









import tippy from "tippy.js";

export default {
  name: "TreeCellLed",
  props: ["value", "message"],
  watch: {
    message(msg) {
      this.$refs.led._tippy.setContent(msg);
    }
  },
  mounted() {
    tippy(this.$refs.led, {
      content: this.message,
      onShow: () => {
          if (!this.message) return false;
      }
    });
  }
};
