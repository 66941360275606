import { mapGetters } from 'vuex';
import IconDel from '@/icons/minus-square-solid.svg';

export default {
    name: 'MemberModalLine',
    components: { IconDel,  },
    props: {
        member: {},
    },
    computed: {
        ...mapGetters({
            resourcesAll: 'Resource/list/resourcesAll',
            roles: 'Enumeration/list/roles',
        }),
        user() {
            return this.resourcesAll.find(res => res.id === this.member.user_id) || {};
        },
        name() {
            return this.user.fullname;
        },
        role() {
            if (!this.roles.length) return '';
            return this.roles.find(rol => rol.id === this.member.role_id).name;
        },
        disabled() {
            return this.user.status !== 1;
        },
    },
};
