import Multiselect from 'vue-multiselect';
import { mapGetters } from "vuex";
import Modal from '@/views/modal/Modal.vue';
import FormError from "@/views/common/FormError.vue";
import { CUSTOM_FIELD_PROJECT_CLIENT } from '@/config/redmine-constants';

export default {
    name: 'NewProjectModal',
    components: {
        FormError,
        Multiselect,
        Modal,
    },
    data() {
        return {
            proj: {
                name: null,
                id: null,
                sub: null,
                client: null,
            },
            errors: {
                id: false,
                name: false,
            },
            errorMessages: [],
        };
    },
    computed: {
        ...mapGetters({
            projects: 'Project/list/projects',
        }),
    },
    methods: {
        validate() {
            const requiredFields = ['name', 'id'];
            requiredFields.forEach(requiredField => {
                this.errors[requiredField] = !this.proj[requiredField];
            });
            return !Object.values(this.errors).find(error => error);
        },
        close() {
            this.$emit('close');
        },
        submit() {
            this.errorMessages = [];
            if (!this.validate()) return;
            const project = {
                name: this.proj.name,
                identifier: this.proj.id,
                parent_id: this.proj.sub?.id,
                custom_field_values: {},
                enabled_module_names: [
                    'issue_tracking',
                    'time_tracking',
                ],
            };
            if (this.proj.client) {
                project.custom_field_values[CUSTOM_FIELD_PROJECT_CLIENT] = this.proj.client;
            }
            this.$store.dispatch('Project/edit/create', project).then(project => {
                this.$emit('submit', project.id);
            }).catch(({ response }) => {
                this.errorMessages = response.data.errors;
            });
        },
    },
};
