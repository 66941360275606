import { mapGetters } from 'vuex';
import Multiselect from "vue-multiselect";
import Modal from "@/views/modal/Modal.vue";
import FormError from "@/views/common/FormError.vue";
import TreeVersionChangerLine from "./TreeVersionChangerLine.vue";

export default {
    name: "TreeVersionChanger",
    components: {
        Multiselect,
        Modal,
        FormError,
        TreeVersionChangerLine,
    },
    props: ['issue', 'versions'],
    data() {
        return {
            targetVersions: this.versions.filter(version => version.id !== -1),
            sourceVersion: this.versions.find(version => version.id === this.issue.version_id),
            targetVersion: null,
            sourceActivities: [], // Used Redmine Profiles for the selected issue
            sourceVCRs: [], // Used CRM Profiles for the selected issue
            targetVCRs: [],
            crmMapping: [],
            redmineMapping: [],
            errors: [],
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            activities: 'Enumeration/list/activities',
        }),
    },
    methods: {
        populateSourceVCRs() {
            return this.$store.dispatch('Credit/list/getProfilesUsedForIssue', this.issue.id)
                .then((vcrs) => {
                    this.sourceVCRs = vcrs.filter(vcr => vcr.redmine === '0');
                    this.sourceActivities = vcrs.filter(vcr => vcr.redmine === '1');
                });
        },
        populateTargetVCRs() {
            if (!this.targetVersion) {
                this.targetVCRs = [];
                this.crmMapping = {};
                this.redmineMapping = {};
                return Promise.resolve();
            }
            return this.$store.dispatch('Credit/list/getProfilesForVersion', this.targetVersion.id)
                .then(({profiles}) => {
                    this.targetVCRs = profiles;
                    // this.targetVCRs = vcrs.concat(this.activities.map(activity => ({
                    //     id: activity.id,
                    //     name: activity.name,
                    //     redmine: '1',
                    //     profile_id: null,
                    // })));
                });
        },
        suggestMapping() {
            if (!this.sourceVCRs.length || !this.targetVCRs.length) return;
            this.crmMapping = this.sourceVCRs.reduce((obj, sourceVCR) => {
                return {
                    ...obj, [sourceVCR.id]: this.targetVCRs.find(targetVCR => {
                        return sourceVCR.profile_id === targetVCR.profile_id;
                    })?.id || null
                };
            }, {});
            this.redmineMapping = this.sourceActivities.reduce((obj, activity) => {
                return {
                    ...obj, [activity.id]: this.targetVCRs.find(targetVCR => {
                        return activity.id == targetVCR.redmine_id;
                    })?.id || null
                };
            }, {});
        },
        selectTargetVersion() {
            this.populateTargetVCRs().then(() => {
                this.suggestMapping();
            });
        },
        changeVersion() {
            this.errors = [];
            if (!this.targetVersion) return;
            // if (Object.values(this.crmMapping).some(entry => !entry)
            //   || Object.values(this.redmineMapping).some(entry => !entry)
            // ) {
            //     return this.errors = ['Toutes les correspondances doivent etre renseignées']
            // };
            let promises = [];
            if (this.sourceVersion !== this.targetVersion) {
                promises.push(this.$store.dispatch("Issue/edit/update", {
                    issueId: this.issue.id,
                    issue: { fixed_version_id: this.targetVersion.id }
                }));
            }
            if (Object.entries(this.crmMapping).length
                || Object.entries(this.redmineMapping).length
            ) {
                promises.push(this.$store.dispatch('TimeEntry/list/mapProfile', {
                    issueId: this.issue.id,
                    crmMapping: this.crmMapping,
                    redmineMapping: this.redmineMapping,
                }));
            }
            Promise.all(promises).then(() => {
                this.$emit('refresh');
                this.$emit('close');
            });
        },
        close() {
            this.$emit('close');
        },
    },
    mounted() {
        this.$store.dispatch('Enumeration/list/getActivities');
        this.targetVersion = this.sourceVersion;
        const promises = [];
        promises.push(this.populateSourceVCRs());
        promises.push(this.populateTargetVCRs());
        Promise.all(promises).then(() => {
            this.$nextTick(() => {
                // Fixes a kind of race condition issue
                // Preventing the mapping selects to set their values
                this.suggestMapping();
            });
        });
    }
}
