import { getCustomFieldValue } from '@/app/helpers';
import { CUSTOM_FIELD_VERSION_TYPE } from '@/config/redmine-constants';
import IconDel from '@/icons/minus-square-solid.svg';
import VersionModalForm from './VersionModalForm.vue';


export default {
    name: 'VersionModalLine',
    components: { IconDel, VersionModalForm, },
    props: {
        version: {},
        edit: {
            type: Boolean,
            default: false,
        },
        projectId: {
            type: Number,
        },
        used: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        type() {
            return getCustomFieldValue(this.version, CUSTOM_FIELD_VERSION_TYPE);
        },
    },
    methods: {
        remove() {
            if (!window.confirm('Are you sure you want to delete this version ?')) return;
            this.$store.dispatch('Project/version/remove', this.version.id).then(() => { this.$emit('submit'); });
        },
    },
};
