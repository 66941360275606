import IconAdd from '@/icons/plus-square-solid.svg';
import Modal from '@/views/modal/Modal.vue';
import VersionModalLine from './VersionModalLine.vue';

export default {
    name: 'VersionsModal',
    components: {
        Modal,
        IconAdd,
        VersionModalLine,
    },
    props: {
        project: {}
    },
    data() {
        return {
            versionsAll: [],
            showClosed: false,
            versionEditing: null,
            usedVersions: [],
        };
    },
    computed: {
        versions() {
            if (this.showClosed) return this.versionsAll;
            return this.versionsAll.filter(v => v.status === 'open');
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        refresh() {
            this.$store.dispatch('Issue/list/getVersions', this.project.id).then(versions => {
                this.versionsAll = versions;
            });
        },
        submitted() {
            this.versionEditing = null;
            this.refresh();
        },
    },
    mounted() {
        this.refresh();
        this.$store.dispatch('Enumeration/customFields/getList');
        this.$store.dispatch('Project/version/getUsed', this.project.id).then((usedVersions) => {
            this.usedVersions = usedVersions;
        });
    }
};
