<template>
  <label class="time_entry custom-control custom-checkbox">
    <input
      type="checkbox"
      class="custom-control-input"
      v-model="selected"
      :value="value"
    />
    <div class="custom-control-label">
      {{ value.spent_on }} - {{ value.hours }}h
      <span v-if="value.future_time != 1" class="real_checkmark">&check;</span>
    </div>
    <icon-comment
      v-if="hasComments"
      ref="comment"
      :data-tippy-content="value.comments"
    />
  </label>
</template>

<script>
import tippy from "tippy.js";
import IconComment from "@/icons/comment-alt-solid.svg";

export default {
  name: "TreeTimeMoverEntry",
  model: { prop: "entries" },
  components: { IconComment },
  props: {
    value: {},
    entries: {},
  },
  computed: {
    selected: {
      get() {
        return this.entries;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    hasComments() {
      return this.value.comments && this.value.comments !== ".";
    },
  },
  mounted() {
    if (this.hasComments) {
      tippy(this.$refs.comment);
    }
  },
};
</script>
