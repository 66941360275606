import Draggable from "vuedraggable";
import { delegate as tippyDelegate } from "tippy.js";
import FlatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import { mapGetters } from "vuex";

import IconAdd from "@/icons/plus-square-solid.svg";
import IconComment from "@/icons/comment-alt-solid.svg";
import IconExternalLink from "@/icons/external-link-square-alt-solid.svg";
import IconRemove from "@/icons/trash-solid.svg";
import IconMoveLeft from "@/icons/arrow-alt-circle-left-solid.svg";
import IconMoveDown from "@/icons/arrow-alt-circle-down-solid.svg";
import IconDevops from "@/icons/azure-devops.svg";
import IconBlank from "@/icons/blank.svg";
import IconHandle from "@/icons/grip-lines-solid.svg";
import IconDots from "@/icons/ellipsis-v-solid.svg";
import IconRename from "@/icons/pen-square-solid.svg";
import IconModal from "@/icons/file-solid.svg";
import IconPlan from "@/icons/calendar-plus-solid.svg";
import * as Redmine from '@/config/redmine-constants';
import {
    formatRow,
    formatCell,
    formatKCell,
    getRAF,
    getDPP,
    getDAD,
    getSWISLed,
    getMasaoLed,
    formatTimeEntriesForIssue,
    treeNodeCompare,
} from "../tree-helpers";
import TreeCellEdit from "./TreeCellEdit.vue";
import TreeCellLed from "./TreeCellLed.vue";
import TreeNodeAdd from "./TreeNodeAdd.vue";
import TreeNodeComment from "./TreeNodeComment.vue";
import TreeNodeStatus from "./TreeNodeStatus.vue";
import TreeNodeDoneRatio from "./TreeNodeDoneRatio.vue";
import TreeNodeRename from "./TreeNodeRename.vue";
import TreeNodeVersion from "./TreeNodeVersion.vue";
import IssueModal from "@/views/calendar/IssueModal.vue";
import { getIssueLink } from '@/app/helpers';
// @ts-ignore
const TimeModal = () => import(/* webpackChunkName: "timemodal" */ "@/views/time-modal/TimeModal.vue");


export default {
    name: "TreeNode",
    components: {
        TreeCellEdit,
        TreeCellLed,
        TreeNodeAdd,
        TreeNodeComment,
        TreeNodeStatus,
        TreeNodeDoneRatio,
        TreeNodeRename,
        TreeNodeVersion,
        IconAdd,
        IconComment,
        IconExternalLink,
        IconRemove,
        IconMoveLeft,
        IconMoveDown,
        IconDevops,
        IconBlank,
        IconHandle,
        IconDots,
        IconRename,
        IconModal,
        IconPlan,
        IssueModal,
        Draggable,
        FlatPickr,
        TimeModal,
    },
    props: {
        node: Object,
        options: {
            type: Object,
            required: true,
        },
        openPath: Array,
    },
    data() {
        return {
            cellToEdit: null,
            childToAdd: null,
            nodeToComment: null,
            nodeToStatus: null,
            nodeToDoneRatio: null,
            nodeToRename: null,
            nodeToVersion: null,
            nodeToModal: null,
            nodeToPlan: null,
            highlight: false,
            open: this.options.open || false,
            level: this.options.level || 0,
            projectEditable: this.options.projectEditable,
            devops: this.options.devops || false,
            advanced: this.options.advanced,
            dateConfig: { locale: French },
            contextMenu: false,
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            user: "Resource/auth/user",
            cia: "App/colSet/cia",
            noColSet: "App/colSet/noColSet",
        }),
        issue() {
            return this.node?.data;
        },
        devopsNode() {
            return !!this.issue.devops_url;
        },
        row() {
            return formatRow(this.issue);
        },
        children() {
            return this.node.children;
        },
        aggRaa() {
            return this.issue.agg_raa;
        },
        aggRaaCell() {
            return formatCell(this.aggRaa);
        },
        swis() {
            return getSWISLed(this.issue.agg_estimated_hours, this.aggRaa);
        },
        swisLed() {
            return !this.swis;
        },
        swisLedMessage() {
            return this.swis;
        },
        masao() {
            return getMasaoLed(this.issue, this.aggRaa);
        },
        masaoLed() {
            return !this.masao;
        },
        masaoLedMessage() {
            return this.masao;
        },
        comment() {
            return this.issue.comment;
        },
        hasChildren() {
            return this.issue.children_nb > 0;
        },
        commentTooltip() {
            const subComment = this.subActive ? '<div class="tooltip_foot_text">Enfant(s) commenté(s)</div>' : '';
            if (!this.comment) return 'Commenter' + subComment;
            return this.comment.replace(/\n/g, '<br />') + subComment;
        },
        canDeleteNode() {
            if (!this.projectEditable) return false;
            if (this.devopsNode) return false;
            if (this.hasChildren) return false;
            if ((this.issue.agg_hours || 0) > 0) return false;
            return true;
        },
        subActive() {
            return this.issue.sub_comment_nb > 0;
        },
        isClosed() {
            if (!this.issue) return false;
            return this.issue.status_id === Redmine.ISSUE_STATUS_CLOSED;
        },
        isRemoved() {
            if (!this.issue) return false;
            return this.issue.status_id === Redmine.ISSUE_STATUS_REMOVED;
        },
        canHoursToEstimated() {
            if (!this.projectEditable) return false;
            if (!this.isRemoved) return false;
            if (!(this.issue.hours || 0)) return false;
            return true;
        },
        canMoveTimeEntriesToOtherIssue() {
            if (!this.projectEditable) return false;
            if (!(this.issue.hours || 0)) return false;
            return true;
        },
        nameTooltip() {
            let tooltip = this.issue.subject;
            if (this.devopsNode) {
                tooltip += '<div class="font-weight-bold font-italic">DevOps</div>';
            }
            if (this.children.length) {
                tooltip += '<div class="tooltip_foot_text">Cliquer pour ouvrir/fermer</div>';
            }
            return tooltip;
        },
        canChangeDoneRatio() {
            return !this.hasChildren;
        },
        canChangeStatus() {
            return !this.devopsNode;
        },
        canChangeVersion() {
            return this.projectEditable && (this.issue.version_open || !this.issue.version_id);
        },
        canPlan() {
            return !this.hasChildren && !this.isClosed && !this.isRemoved && this.issue.version_open;
        },
        drcc: {
            get() {
                return this.issue.drcc ? this.issue.drcc.split(' ')[0] : null;
            },
            set(drcc) {
                this.issue.drcc = drcc;
                this.$store
                    .dispatch("Issue/edit/setExtra", {
                        issueId: this.issue.id,
                        issue: { drcc }
                    });
            }
        },
        raf() {
            return formatKCell(getRAF(this.issue));
        },
        dpp() {
            return formatKCell(getDPP(this.issue));
        },
        dad() {
            return formatKCell(getDAD(this.issue));
        },
        nodeHoldingDoneRatio() {
            return this.hasChildren && this.issue.done_ratio;
        },
    },
    methods: {
        toggleOpen() {
            if (!this.children.length) return;
            this.open = !this.open;
        },
        editCell(e) {
            if (!this.projectEditable) return;
            this.cellToEdit = {
                issue: this.issue,
                dataset: e.target.dataset,
                element: e.target
            };
        },
        closeCell() {
            this.cellToEdit = null;
        },
        openAddingChild(e) {
            if (!this.projectEditable) return;
            this.childToAdd = {
                parentIssue: this.issue,
                parentElement: this.$refs.name
            };
        },
        closeAddingChild() {
            this.childToAdd = null;
        },
        openNodeComment(e) {
            if (!this.projectEditable) return;
            this.nodeToComment = {
                issue: { id: this.issue.id, comment: this.comment },
                element: this.$refs.name
            };
        },
        closeNodeComment() {
            this.nodeToComment = null;
        },
        openNodeStatus() {
            if (!this.canChangeStatus) return;
            this.nodeToStatus = {
                issue: { id: this.issue.id, statusId: this.issue.status_id },
                element: this.$refs.status
            };
        },
        closeNodeStatus() {
            this.nodeToStatus = null;
        },
        getTimeEntriesTooltipRendered(passed = 1) {
            return this.$store.dispatch('TimeEntry/list/getForIssue', {
                issueId: this.issue.id,
                passed
            }).then(formatTimeEntriesForIssue);
        },
        redmineIssueLink() {
            return getIssueLink(this.issue.id);
        },
        deleteNode() {
            if (!this.canDeleteNode) return;
            if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette tâche ?')) return;
            const vm = this;
            this.$store.dispatch('Issue/edit/delete', this.issue.id).then(() => {
                this.$gtag.event('Delete Task', {
                    event_category: 'SPA',
                });
                vm.$emit('refresh');
            });
        },
        peccToSold() {
            if (!window.confirm('Êtes-vous sûr de vouloir déplacer le PECC dans le Vendu ?')) return;
            const promises = [
                this.$store.dispatch("Issue/edit/setExtra", {
                    issueId: this.issue.id,
                    issue: { peccHours: 0 }
                }),
                this.$store.dispatch("Issue/edit/update", {
                    issueId: this.issue.id,
                    issue: { sold_hours: (parseFloat(this.issue.sold_hours) || 0) + parseFloat(this.issue.pecc_hours) }
                }),
            ];
            const vm = this;
            Promise.all(promises).then(() => {
                vm.$emit('refresh');
            });
        },
        hoursToEstimated() {
            if (!window.confirm('Êtes-vous sûr de vouloir copier le réalisé/planifié sur l\'estimé')) return;
            const vm = this;
            this.$store.dispatch("Issue/edit/update", {
                issueId: this.issue.id,
                issue: { estimated_hours: (parseFloat(this.issue.hours) || 0) }
            }).then(() => {
                vm.$emit('refresh');
            });
        },
        moveTimeEntries(data) {
            const issue = data.hasOwnProperty('id') ? data : this.issue;
            this.$emit('moveTimeEntries', issue);
        },
        changeVersion(data) {
            const issue = data || this.issue;
            this.$emit('changeVersion', issue);
        },
        openNodeDoneRatio() {
            if (!this.canChangeDoneRatio) return;
            this.nodeToDoneRatio = {
                issue: this.issue,
                element: this.$refs.doneRatio
            };
        },
        closeNodeDoneRatio() {
            this.nodeToDoneRatio = null;
        },
        openNodeRename() {
            if (!this.projectEditable && !this.devopsNode) return;
            this.nodeToRename = {
                issue: this.issue,
                element: this.$refs.name
            };
        },
        closeNodeRename() {
            this.nodeToRename = null;
        },
        openNodeVersion() {
            if (!this.canChangeVersion) return;
            if (this.issue.hours) return this.changeVersion();
            this.nodeToVersion = {
                issue: this.issue,
                element: this.$refs.version
            };
        },
        closeNodeVersion() {
            this.nodeToVersion = null;
        },
        moveNode() {
            if (!window.confirm('Êtes-vous sur de vouloir changer l\'ordre ?')) {
                this.node.children.sort(treeNodeCompare);
                return;
            }
            const issueIds = this.node.children.map(issue => issue.data.id);
            this.$store.dispatch('Issue/edit/setOrder', issueIds);
        },
        openNodeModal() {
            if (!this.advanced) return;
            this.nodeToModal = this.issue.id;
        },
        closeNodeModal() {
            this.nodeToModal = null;
        },
        openNodePlan() {
            this.nodeToPlan = {
                resourceId: this.user.id,
                projectId: this.issue.project_id,
                issueId: this.issue.id,
            };
        },
        closeNodePlan() {
            this.nodeToPlan = null;
        },
        showHoursTooltip(instance, passed) {
            if (passed && !this.issue.passed_hours) return false;
            if (!passed && !this.issue.planned_hours) return false;
            if (!!instance.props.content) return false;
            this.getTimeEntriesTooltipRendered(passed).then(content => {
                instance.setContent(content);
                if (instance.popper.dataset.clickable) return;
                instance.popper.addEventListener('click', (e) => {
                    if (e.target.className !== 'calendar_link') return;
                    this.$router.push({ name: 'calendar', params: { timeEntryId: e.target.dataset.tieId } })
                    instance.hide();
                });
                instance.popper.dataset.clickable = true;
            });
        },
        tipNode() {
            const vm = this;
            tippyDelegate(this.$refs.name, {
                multiple: true,
                target: '.node_name',
                content: this.nameTooltip
            });
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.node_delete',
                content: "Supprimer"
            });
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.node_add',
                content: "Ajouter une tâche enfant"
            });
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.node_plan',
                content: "Saisir du temps"
            });
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.node_move',
                content: "Déplacer"
            });
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.node_rename',
                content: "Renommer"
            });
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.node_comment',
                content: this.commentTooltip
            });
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.node_devops',
                content: "Lien DevOps"
            });
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.pecc_to_sold',
                content: "Passer en vendu"
            });
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.node_issue_modal',
                content: "Voir la fiche"
            });
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.node_redmine',
                content: "Voir sur Redmine"
            });
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.node_passed_hours',
                trigger: 'click',
                interactive: true,
                appendTo: document.body,
                arrow: false,
                maxWidth: 'none',
                onShow(instance) {
                    vm.showHoursTooltip(instance, 1);
                },
            });
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.node_planned_hours',
                trigger: 'click',
                interactive: true,
                appendTo: document.body,
                arrow: false,
                onShow(instance) {
                    vm.showHoursTooltip(instance, 0);
                },
            });
            tippyDelegate(this.$refs.node, {
                multiple: true,
                target: '.hours_to_estimated',
                content: "Copier le réalisé/planifié sur l'estimé"
            });
            tippyDelegate(this.$refs.node, {
                multiple: true,
                target: '.time_entries_to_issue',
                content: "Déplacer le temps saisi sur une autre tâche"
            });
            const extraPhase = this.issue.phase === 'Recette' || this.issue.phase === 'Projet';
            const flatRateVersion = this.issue.version_type === Redmine.CUSTOM_FIELD_VERSION_TYPE_FLATRATE;
            if (this.aggRaa > 0 && !extraPhase && flatRateVersion) {
                tippyDelegate(vm.$refs.node, {
                    multiple: true,
                    target: '.node_raa',
                    content: "Pensez au % de chefferie de projet et de recette lié au RAA"
                });
            }
            tippyDelegate(vm.$refs.node, {
                multiple: true,
                target: '.node_done_ratio',
                onShow(instance) {
                    if (!vm.nodeHoldingDoneRatio) return false;
                    const content = `Ce nœud porte une progression non nulle: ${vm.issue.done_ratio} %`;
                    instance.setContent(content);
                }
            });
        },
        showPath() {
            if (!this.issue) return;
            this.highlight = false;
            if (!this.openPath) return;
            if (this.openPath.slice(0, -1).includes(this.issue.id)) {
                this.open = true;
            }
            if (this.openPath.slice(-1).includes(this.issue.id)) {
                this.highlight = true;
                this.$nextTick(function () {
                    // Prevents race condition with router.replace
                    this.$refs.name.scrollIntoView({ block: "center", inline: "nearest" });
                });
            }
        },
    },
    watch: {
        options() {
            this.advanced = this.options.advanced;
            this.projectEditable = this.options.projectEditable;
        },
        openPath() {
            this.showPath()
        }
    },
    mounted() {
        if (this.issue) {
            this.tipNode();
            this.showPath();
        }
    }
};
