var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"mouseleave":function($event){_vm.contextMenu = false}}},[(_vm.issue)?_c('div',{ref:"node",class:['tree_node', { open: _vm.open, highlight: _vm.highlight, has_children: _vm.children.length }]},[_c('div',{ref:"name",staticClass:"cell_first node_name",style:('margin-left: ' + _vm.level * 30 + 'px'),on:{"click":_vm.toggleOpen}},[_vm._v(" "+_vm._s(_vm.issue.subject)+" ")]),(_vm.projectEditable)?_c('div',{class:['cell_action', 'node_move', 'handle', { hidden: !_vm.contextMenu }]},[_c('IconHandle')],1):_vm._e(),(_vm.canDeleteNode)?_c('div',{class:['cell_action', 'node_delete', { hidden: !_vm.contextMenu }],on:{"click":_vm.deleteNode}},[_c('IconRemove')],1):_vm._e(),(_vm.projectEditable && !_vm.devopsNode)?_c('div',{class:['cell_action', 'node_rename', { hidden: !_vm.contextMenu }],on:{"click":_vm.openNodeRename}},[_c('IconRename')],1):_vm._e(),(_vm.projectEditable)?_c('div',{class:['cell_action', 'node_add', { hidden: !_vm.contextMenu }],on:{"click":_vm.openAddingChild}},[_c('IconAdd')],1):_vm._e(),(_vm.canPlan)?_c('div',{class:['cell_action', 'node_plan', { hidden: !_vm.contextMenu }],on:{"click":_vm.openNodePlan}},[_c('IconPlan')],1):_vm._e(),(_vm.advanced)?_c('a',{class:['cell_action', 'node_redmine', { hidden: !_vm.contextMenu }],attrs:{"href":_vm.redmineIssueLink(),"target":"_blank"}},[_c('IconExternalLink')],1):_vm._e(),(_vm.advanced)?_c('div',{class:['cell_action', { hidden: _vm.contextMenu }],on:{"click":function($event){_vm.contextMenu = !_vm.contextMenu}}},[_c('IconDots')],1):_vm._e(),(_vm.advanced)?_c('div',{class:['cell_action', 'node_issue_modal'],on:{"click":_vm.openNodeModal}},[_c('IconModal')],1):_vm._e(),(_vm.projectEditable)?_c('div',{class:[
        'cell_action', 'node_comment',
        { active: !!_vm.comment, sub_active: !_vm.comment && _vm.subActive } ],on:{"click":_vm.openNodeComment}},[_c('IconComment')],1):_vm._e(),_c('div',{staticClass:"cell_action indicator node_devops"},[(_vm.devopsNode)?_c('a',{attrs:{"href":_vm.issue.devops_url,"target":"_blank"}},[_c('icon-devops')],1):_c('IconBlank')],1),(_vm.cia.status)?_c('div',{ref:"status",class:[
        'cell_55',
        'show_on_hover',
        'no_ellipsis',
        { editable: _vm.canChangeStatus, hidden_xs: _vm.noColSet } ],on:{"click":_vm.openNodeStatus}},[_vm._v(" "+_vm._s(_vm.issue.status)+" ")]):_vm._e(),(_vm.cia.version)?_c('div',{ref:"version",class:[
        'cell_100',
        'show_on_hover',
        { editable: _vm.canChangeVersion, hidden_lg: _vm.noColSet } ],on:{"click":_vm.openNodeVersion}},[_vm._v(" "+_vm._s(_vm.issue.version)+" ")]):_vm._e(),(_vm.cia.drcc && _vm.advanced && _vm.projectEditable)?_c('flat-pickr',{class:['cell_100', 'text-center', { hidden_lg: _vm.noColSet }],attrs:{"config":_vm.dateConfig},model:{value:(_vm.drcc),callback:function ($$v) {_vm.drcc=$$v},expression:"drcc"}}):_vm._e(),(_vm.cia.drcc && !(_vm.advanced && _vm.projectEditable))?_c('div',{class:['cell_100', 'text-center', { hidden_lg: _vm.noColSet }]},[_vm._v(" "+_vm._s(_vm.drcc)+" ")]):_vm._e(),(_vm.cia.pc)?_c('div',{class:['cell_num', 'bleft', { hidden_lg: _vm.noColSet }]},[_vm._v(" "+_vm._s(_vm.row.agg_init_sold_hours)+" ")]):_vm._e(),(_vm.cia.pc)?_c('div',{class:[
        'cell_num',
        'node',
        { editable: _vm.projectEditable, hidden_lg: _vm.noColSet } ],attrs:{"data-property":"init_sold_hours","data-type":"extra"},on:{"click":_vm.editCell}},[_vm._v(" "+_vm._s(_vm.row.init_sold_hours)+" ")]):_vm._e(),(_vm.cia.sold)?_c('div',{staticClass:"cell_num bleft"},[_vm._v(_vm._s(_vm.row.agg_sold_hours))]):_vm._e(),(_vm.cia.sold)?_c('div',{class:['cell_num', 'node', { editable: _vm.projectEditable }],attrs:{"data-property":"sold_hours"},on:{"click":_vm.editCell}},[_vm._v(" "+_vm._s(_vm.row.sold_hours)+" ")]):_vm._e(),(_vm.cia.estimated)?_c('div',{staticClass:"cell_num bleft"},[_vm._v(" "+_vm._s(_vm.row.agg_estimated_hours)+" ")]):_vm._e(),(_vm.cia.estimated)?_c('div',{class:['cell_num', 'node', { editable: _vm.projectEditable }],attrs:{"data-property":"estimated_hours"},on:{"click":_vm.editCell}},[_vm._v(" "+_vm._s(_vm.row.estimated_hours)+" ")]):_vm._e(),(_vm.cia.pecc)?_c('div',{staticClass:"cell_num bleft"},[_vm._v(_vm._s(_vm.row.agg_pecc_hours))]):_vm._e(),(_vm.cia.pecc)?_c('div',{class:[
        'movable_hours',
        'cell_num',
        'node',
        { editable: _vm.projectEditable } ],attrs:{"data-property":"pecc_hours","data-type":"extra"},on:{"click":_vm.editCell}},[_vm._v(" "+_vm._s(_vm.row.pecc_hours)+" "),_c('div',{class:['pecc_to_sold', { hidden: !(_vm.issue.pecc_hours > 0) }],on:{"click":function($event){$event.stopPropagation();return _vm.peccToSold.apply(null, arguments)}}},[_c('icon-move-left')],1)]):_vm._e(),(_vm.cia.pecm)?_c('div',{staticClass:"cell_num bleft"},[_vm._v(_vm._s(_vm.row.agg_pecm_hours))]):_vm._e(),(_vm.cia.pecm)?_c('div',{class:['cell_num', 'node', { editable: _vm.projectEditable }],attrs:{"data-property":"pecm_hours","data-type":"extra"},on:{"click":_vm.editCell}},[_vm._v(" "+_vm._s(_vm.row.pecm_hours)+" ")]):_vm._e(),(_vm.cia.raa)?_c('div',{class:['cell_num', 'bleft', 'node_raa', { hidden_xs: _vm.noColSet }]},[_vm._v(" "+_vm._s(_vm.aggRaaCell)+" ")]):_vm._e(),(_vm.cia.s)?_c('tree-cell-led',{attrs:{"value":_vm.swisLed,"message":_vm.swisLedMessage}}):_vm._e(),(_vm.advanced && _vm.cia.passed)?_c('div',{staticClass:"cell_num"},[_vm._v(" "+_vm._s(_vm.row.agg_passed_hours)+" ")]):_vm._e(),(_vm.advanced && _vm.cia.passed)?_c('div',{class:[
        'cell_num',
        'node',
        'node_passed_hours',
        { cpointer: !!_vm.issue.passed_hours } ]},[_vm._v(" "+_vm._s(_vm.row.passed_hours)+" ")]):_vm._e(),(_vm.advanced && _vm.cia.planned)?_c('div',{class:['movable_hours', 'cell_num', 'bleft', { hidden_md: _vm.noColSet }]},[_c('div',{class:[
          'time_entries_to_issue',
          { hidden: !_vm.canMoveTimeEntriesToOtherIssue } ],on:{"click":function($event){$event.stopPropagation();return _vm.moveTimeEntries.apply(null, arguments)}}},[_c('icon-move-down')],1),_c('div',{class:['hours_to_estimated', { hidden: !_vm.canHoursToEstimated }],on:{"click":function($event){$event.stopPropagation();return _vm.hoursToEstimated.apply(null, arguments)}}},[_c('icon-move-left')],1),_vm._v(" "+_vm._s(_vm.row.agg_planned_hours)+" ")]):_vm._e(),(_vm.advanced && _vm.cia.planned)?_c('div',{class:[
        'cell_num',
        'node',
        'node_planned_hours',
        { cpointer: !!_vm.issue.planned_hours, hidden_md: _vm.noColSet } ]},[_vm._v(" "+_vm._s(_vm.row.planned_hours)+" ")]):_vm._e(),(_vm.cia.done_ratio)?_c('div',{ref:"doneRatio",class:[
        'cell_perc',
        'node_done_ratio',
        { editable: _vm.canChangeDoneRatio, abbr: _vm.nodeHoldingDoneRatio } ],on:{"click":_vm.openNodeDoneRatio}},[_vm._v(" "+_vm._s(_vm.issue.agg_done_ratio)+" % ")]):_vm._e(),(_vm.advanced && _vm.cia.raf)?_c('div',{class:['cell_num', 'text-right', { hidden_lg: _vm.noColSet }]},[_vm._v(" "+_vm._s(_vm.raf)+" ")]):_vm._e(),(_vm.advanced && _vm.cia.dpp)?_c('div',{class:['cell_num', 'text-right', { hidden_lg: _vm.noColSet }]},[_vm._v(" "+_vm._s(_vm.dpp)+" ")]):_vm._e(),(_vm.advanced && _vm.cia.dad)?_c('div',{class:['cell_num', 'text-right', { hidden_lg: _vm.noColSet }]},[_vm._v(" "+_vm._s(_vm.dad)+" ")]):_vm._e(),(_vm.advanced && _vm.cia.m)?_c('tree-cell-led',{attrs:{"value":_vm.masaoLed,"message":_vm.masaoLedMessage}}):_vm._e(),(_vm.cellToEdit)?_c('tree-cell-edit',{attrs:{"cell":_vm.cellToEdit},on:{"close":_vm.closeCell,"submitted":function($event){return _vm.$emit('refresh')}}}):_vm._e(),(_vm.childToAdd)?_c('tree-node-add',{attrs:{"childNode":_vm.childToAdd},on:{"close":_vm.closeAddingChild,"submitted":function($event){_vm.open = true;
        _vm.$emit('refresh');}}}):_vm._e(),(_vm.nodeToComment)?_c('tree-node-comment',{attrs:{"cell":_vm.nodeToComment},on:{"close":_vm.closeNodeComment,"submitted":function($event){return _vm.$emit('refresh')}}}):_vm._e(),(_vm.nodeToStatus)?_c('tree-node-status',{attrs:{"cell":_vm.nodeToStatus},on:{"close":_vm.closeNodeStatus,"submitted":function($event){return _vm.$emit('refresh')}}}):_vm._e(),(_vm.nodeToDoneRatio)?_c('tree-node-done-ratio',{attrs:{"cell":_vm.nodeToDoneRatio},on:{"close":_vm.closeNodeDoneRatio,"submitted":function($event){return _vm.$emit('refresh')}}}):_vm._e(),(_vm.nodeToRename)?_c('tree-node-rename',{attrs:{"cell":_vm.nodeToRename},on:{"close":_vm.closeNodeRename,"submitted":function($event){return _vm.$emit('refresh')}}}):_vm._e(),(_vm.nodeToVersion)?_c('tree-node-version',{attrs:{"cell":_vm.nodeToVersion},on:{"close":_vm.closeNodeVersion,"submitted":function($event){return _vm.$emit('refresh')}}}):_vm._e()],1):_vm._e(),(_vm.open)?_c('draggable',{attrs:{"disabled":false,"list":_vm.node.children,"handle":".handle","ghost-class":"moving"},on:{"change":_vm.moveNode}},_vm._l((_vm.children),function(child){return _c('tree-node',{key:child.data.id,attrs:{"node":child,"options":{ level: _vm.level + 1, projectEditable: _vm.projectEditable, devops: _vm.devops, advanced: _vm.advanced },"openPath":_vm.openPath},on:{"refresh":function($event){return _vm.$emit('refresh')},"moveTimeEntries":_vm.moveTimeEntries,"changeVersion":_vm.changeVersion}})}),1):_vm._e(),(_vm.nodeToModal)?_c('issue-modal',{attrs:{"issueId":_vm.nodeToModal},on:{"close":_vm.closeNodeModal,"plan":_vm.openNodePlan,"update":function($event){return _vm.$emit('refresh')}}}):_vm._e(),(_vm.nodeToPlan)?_c('time-modal',{attrs:{"selection":_vm.nodeToPlan},on:{"close":_vm.closeNodePlan,"saved":function($event){return _vm.$emit('refresh')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }