
















import * as Redmine from '@/config/redmine-constants';

export default {
  name: "TreeNodePriority",
  props: ["issue"],
  data() {
    return {
      priorityId: null,
      originalPriorityId: null,
      submitting: false,
    };
  },
  computed: {
    priorities() {
      return Redmine.ISSUE_PRIORITIES.map(prio => ({
        id: prio.id,
        name: prio.devops + ' - ' + prio.redmine,
      }));
    },
    style() {
      return { top: '-4px', left: '-4px' };
    }
  },
  methods: {
    init() {
      this.priorityId = this.issue.priorityId;
      this.originalPriorityId = this.priorityId;
    },
    submit() {
      if (this.priorityId === this.originalPriorityId) return this.close();
      if (this.submitting) return;
      this.submitting = true;
      const vm = this;
      this.$store
        .dispatch("Issue/edit/update", {
          issueId: this.issue.id,
          issue: { priority_id: this.priorityId }
        })
        .then(() => {
          vm.$emit("submitted");
          vm.close();
        });
    },
    close() {
      this.$emit("close");
    }
  },
  mounted() {
    this.init();
    this.$nextTick(() => {
      this.$refs.input.focus();
    });
  }
};
