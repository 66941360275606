













import { elementOffsetCoords } from "../tree-helpers";

function createIssueFromParent(parent, subject) {
  const issue = {
    parent_issue_id: parent.id,
    subject,
    fixed_version_id: parent.version_id,
    project_id: parent.project_id,
    phase: parent.phase || "Paramétrage",
  };
  return issue;
}

function createIssueFromProject(project_id, subject) {
  const issue = {
    subject,
    project_id,
    phase: "Paramétrage",
  };
  return issue;
}

export default {
  name: "TreeNodeAdd",
  props: ["childNode"],
  data() {
    return {
      subject: null,
      submitting: false,
    };
  },
  computed: {
    style() {
      const cellOffsetCoords = elementOffsetCoords(this.childNode.parentElement);
      return {
        top: `${cellOffsetCoords.bottom}px`,
        left: `${cellOffsetCoords.left + 30}px`,
        width: "300px",
      };
    },
  },
  methods: {
    validate() {
      if (!this.subject) return false;
      return this.subject.length > 2;
    },
    submit() {
      if (!this.validate()) return;
      if (this.submitting) return;
      this.submitting = true;
      const subject = this.subject;
      const parent = this.childNode.parentIssue;
      let newIssue = null;
      if (parent.id) {
        newIssue = createIssueFromParent(parent, subject);
      } else {
        newIssue = createIssueFromProject(parent.project_id, subject);
      }
      const vm = this;
      this.$store.dispatch("Issue/edit/create", newIssue).then(() => {
        this.$gtag.event("Create Task", {
          event_category: "SPA",
        });
        vm.$emit("submitted");
        vm.close();
      });
    },
    blur() {
      if (!this.validate()) return this.close();
      this.submit();
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.focus();
    });
  },
};
