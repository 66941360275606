import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import {
    toTree,
    storeProjectFilters,
    getStoredProjectFilters
} from "./tree-helpers";
import {
    userIsCPDPForProject,
    userIsMemberOfProject,
    userIsClient,
    getCustomFieldValue,
    normalizedContains,
    processXlsxResponse,
} from "@/app/helpers";
import bookmarkMixin from "@/utils/bookmark-mixin";
import * as Redmine from "@/config/redmine-constants";
import TreeTop from "./TreeTop.vue";
import TreeTrunk from "./TreeTrunk.vue";
import TreeNode from "./node/TreeNode.vue";
import TreeTimeMover from "./node/TreeTimeMover.vue";
import TreeVersionChanger from "./node/TreeVersionChanger.vue";
import ProjectSettings from "./ProjectSettings.vue";
import VersionModal from "./version-modal/VersionModal.vue";
import MemberModal from "./member-modal/MemberModal.vue";
import NewProjectModal from "./new-project-modal/NewProjectModal.vue";
import ColSetModal from "./ColSetModal.vue";
import IconSettings from "@/icons/cog-solid.svg";
import IconDownload from "@/icons/download-solid.svg";
import IconRefresh from "@/icons/arrow-rotate-right-solid.svg";
import IconProfiles from "@/icons/exchange-alt-solid.svg";
import IconCols from "@/icons/table-columns-solid.svg";
import { SET_COL } from "@/store/modules/app/mutation-types";


const statuses = Redmine.ISSUE_STATUSES_ID_LABEL;


function getDefaultData() {
    return {
        selectedProject: null,
        selectedVersions: [],
        selectedPhases: [],
        selectedStatuses: Redmine.DEFAULT_STATUSES.map(statusId => statuses.find(s => s.id === statusId)),
        selectedIssue: null,
        selectedIterationPath: '',
        indexedIssues: [],
        filteredIssues: [],
        tree: [],
        stickyTop: false,
        selectedCell: null,
        versions: [],
        project: null,
        simplified: false,
        loadingFromRoute: false,
        projectSettingsOpen: false,
        versionModalOpen: false,
        memberModalOpen: false,
        newProjectModalOpen: false,
        colSetModalOpen: false,
        issueToMoveTimeEntriesFrom: null,
        issueToMoveTimeEntriesTo: null,
        issueToChangeVersion: null,
        timeModalSelection: null,
        statuses,
    };
}


export default {
    name: "Tree",
    mixins: [bookmarkMixin('tree')],
    components: {
        Multiselect,
        TreeTop,
        TreeTrunk,
        TreeNode,
        TreeTimeMover,
        TreeVersionChanger,
        ProjectSettings,
        VersionModal,
        MemberModal,
        NewProjectModal,
        ColSetModal,
        IconSettings,
        IconDownload,
        IconRefresh,
        IconProfiles,
        IconCols,
    },
    props: ['type', 'id'],
    data: getDefaultData,
    computed: {
        // @ts-ignore
        ...mapGetters({
            user: "Resource/auth/user",
            phases: "Enumeration/customFields/phases",
            resourcesAll: "Resource/list/resourcesAll",
            colSet: "App/colSet/colSet",
        }),
        projects() {
            const projects = this.$store.getters["Project/list/projects"];
            if (this.user.admin) return projects;
            return projects.filter(project => userIsMemberOfProject(this.user, project));
        },
        canCreateProject() {
            return !!this.user.admin;
        },
        canEditProject() {
            if (!this.selectedProject) return false;
            if (this.user.admin) return true;
            if (!userIsCPDPForProject(this.user, this.selectedProject)) return false;
            return true;
            // return !parseInt(this.selectedProject.devops);
        },
        canConfigProject() {
            if (!this.selectedProject) return false;
            if (this.simplified) return false;
            if (this.user.admin) return true;
            if (!userIsCPDPForProject(this.user, this.selectedProject)) return false;
            return true;
        },
        devops() {
            return !!parseInt(this.selectedProject.devops);
        },
        advanced() {
            return !this.simplified;
        },
        issues() {
            return Object.values(this.indexedIssues)
                .map((issue: any) => {
                    return {
                        id: issue.data.id,
                        name: issue.data.subject,
                        path: issue.path,
                        data: issue.data,
                    };
                })
                .filter(issue => !!issue.id)
                ;
        },
        openPath() {
            return this.selectedIssue ? this.selectedIssue.path : [];
        },
        userIsClient() {
            return userIsClient(this.user);
        },
        CPName() {
            return this.getUserNamesByType('cp_ids');
        },
        DPName() {
            return this.getUserNamesByType('dp_ids');
        },
        RTName() {
            return this.getUserNamesByType('rt_ids', true);
        },
        wgr() {
            if (!this.selectedProject) return null;
            return this.selectedProject.work_geo_restriction;
        },
        wgrTitle() {
            if (!this.selectedProject) return false;
            if (this.wgr === 'WW') return 'Aucune restriction de localisation de la production';
            const prefix = 'Périmètre de production ';
            if (this.wgr === 'EU') return prefix + 'européen';
            if (this.wgr === 'FR') return prefix + 'français';
            return prefix + this.wgr;
        },
    },
    methods: {
        updateRoute() {
            if (this.loadingFromRoute) return;
            const params: any = {};
            if (this.selectedProject) {
                params.type = 'project';
                params.id = this.selectedProject.id;
            }
            if (this.selectedIssue) {
                params.type = 'issue';
                params.id = this.selectedIssue.id;
            }
            this.$router.replace({ name: 'tree', params });
        },
        selectProject() {
            this.updateRoute();
            if (!this.selectedProject) return;
            if (!this.bookmarkLoading) {
                const storedFilters = getStoredProjectFilters(this.selectedProject.id);
                if (storedFilters) this.loadFilters(storedFilters);
                else Object.assign(this.$data, (({ selectedProject, ...o }) => o)(getDefaultData()));
            }
            const projectId = this.selectedProject.id;
            return this.$store.dispatch("Issue/list/getVersions", projectId).then(versions => {
                this.versions = [{ id: -1, name: 'Sans version', status: 'closed' }].concat(versions);
                const versionFilter = (this.bookmarkLoading?.versionIds?.length)
                    ? version => this.bookmarkLoading.versionIds.includes(version.id)
                    : version => version.status === 'open'
                    ;
                this.selectedVersions = this.versions.filter(versionFilter);
                this.bookmarkLoading = null;
                this.refreshTree();
            });
        },
        getFilters() {
            if (!this.selectedProject) return null;
            return {
                projectId: this.selectedProject.id,
                versionIds: this.selectedVersions.map(version => version.id),
                phases: this.selectedPhases,
                statusIds: this.selectedStatuses.map(status => status.id),
                iterationPath: this.selectedIterationPath,
                includeClosed: true,
                includeTotal: true,
                colSet: this.colSet,
            };
        },
        loadFilters(filters) {
            const defaultData = getDefaultData();
            delete defaultData.loadingFromRoute;
            Object.assign(this.$data, defaultData);
            this.bookmarkLoading = filters;
            this.selectedProject = this.projects.find(project => project.id === filters.projectId);
            if (filters?.phases?.length) this.selectedPhases = filters.phases;
            if (filters?.iterationPath) this.selectedIterationPath = filters.iterationPath;
            if (filters?.statusIds) this.selectedStatuses = filters.statusIds.map(statusId => statuses.find(s => s.id === statusId));
            if (filters?.colSet) this.$store.commit('App/colSet/' + SET_COL, filters.colSet);
        },
        getBookmark() {
            return this.getFilters();
        },
        loadBookmark() {
            if (!this.bookmark || !this.bookmark.projectId) return;
            this.loadFilters(this.bookmark);
            this.selectProject();
        },
        refreshTree() {
            if (!this.selectedProject) return;
            storeProjectFilters(this.selectedProject.id, this.getFilters());
            this.$store.dispatch("Issue/list/getList", this.getFilters()).then(({ issues, total }) => {
                this.project = total.id ? total : { id: this.selectedProject.id, name: this.selectedProject.name };
                this.indexedIssues = toTree(issues);
                this.filteredIssues = this.issues;
                this.tree = this.indexedIssues[0].children;
                if (this.loadingFromRoute) {
                    this.loadingFromRoute = false;
                    if (this.type !== 'issue') return;
                    this.selectedIssue = this.issues.find(issue => issue.id == this.id);
                }
            });
        },
        peccToSold() {
          this.$store.dispatch("Issue/list/peccToSold", this.getFilters()).then(() => {
            this.refresh()
          })
        },
        exportFile() {
            if (!this.selectedProject) return;
            const payload = this.getFilters();
            payload.advanced = this.advanced;
            payload.format = 'xlsx';
            this.$store
                .dispatch("Issue/list/getList", payload)
                .then(processXlsxResponse("SPA.xlsx"));
        },
        handleScroll() {
            if (!this.$refs.treeTop) return this.stickyTop = false;
            const treeTopY = this.$refs.treeTop.$el.getBoundingClientRect().top;
            this.stickyTop = treeTopY < 0;
        },
        refresh() {
            // Race condition in Redmine API
            setTimeout(this.refreshTree, 200);
        },
        resetFilters() {
            const defaultData = getDefaultData();
            this.selectedVersions = defaultData.selectedVersions;
            this.selectedPhases = defaultData.selectedPhases;
            this.selectedStatuses = defaultData.selectedStatuses;
            this.selectedIssue = defaultData.selectedIssue;
            this.selectedIterationPath = defaultData.selectedIterationPath;
            this.refreshTree();
        },
        fitnetContracts() {
            if (!this.versions) return '';
            const fitnetContracts = this.versions
                .filter(version => version.status === 'open')
                .map(version => getCustomFieldValue(version, Redmine.CUSTOM_FIELD_VERSION_BDC))
                .filter(fitnetContract => fitnetContract);
            if (!fitnetContracts.length) return '';
            return Array.from(new Set(fitnetContracts)).join(',');
        },
        openProjectSettings() {
            if (!this.selectedProject.fitnetContracts) {
                this.selectedProject.fitnetContracts = this.fitnetContracts();
            }
            this.projectSettingsOpen = true;
        },
        setIssueToMoveTimeEntriesFrom(issueToMoveTimeEntriesFrom) {
            this.issueToMoveTimeEntriesFrom = issueToMoveTimeEntriesFrom;
        },
        unsetIssueToMoveTimeEntriesFrom() {
            this.issueToMoveTimeEntriesFrom = null;
        },
        setIssueToChangeVersion(issueToChangeVersion) {
            this.issueToChangeVersion = issueToChangeVersion;
        },
        unsetIssueToChangeVersion() {
            this.issueToChangeVersion = null;
        },
        versionShortcut(type) {
            if (type === 'none') {
                this.selectedVersions = [];
            }
            if (type === 'open') {
                this.selectedVersions = this.versions.filter(version => version.status === 'open');
            }
            if (type === 'all') {
                this.selectedVersions = this.versions;
            }
            // this.$refs.selectedVersions.deactivate();
            // this.refreshTree();
        },
        searchIssues(search) {
            if (!search) {
                this.filteredIssues = this.issues;
                return;
            }
            this.filteredIssues = this.issues.filter(issue =>
                normalizedContains(search, issue.name)
                || search === issue.data.devops_id
                || search == issue.id
            );
        },
        getUserNamesByType(type, optional = false) {
            const cpIds = this.selectedProject[type];
            if (!cpIds.length) return optional ? null : 'Aucun';
            return cpIds.map(id => (this.resourcesAll.find(res => res.id == id) || {}).fullname).join(', ');
        },
        projectCreated(projectId) {
            this.$store.dispatch('Project/list/getList', true).then(() => {
                this.selectedProject = this.projects.find(proj => proj.id === projectId);
                this.selectProject();
            });
        },
        openColSetModal() {
            this.colSetModalOpen = true;
        },
        setCols(colSet) {
            this.$store.commit('App/colSet/' + SET_COL, colSet);
        },
    },
    watch: {
        colSet() {
            if (!this.selectedProject) return;
            storeProjectFilters(this.selectedProject.id, this.getFilters());
        },
    },
    mounted() {
        if (this.userIsClient) {
            this.simplified = true;
        }
        this.$store.dispatch("Enumeration/customFields/getList");
        window.removeEventListener("scroll", this.handleScroll);
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch("Project/list/getList").then(() => {
                let selectedProject = null;
                let promise = Promise.resolve();
                let projectId;
                if (vm.type) {
                    vm.loadingFromRoute = true;
                    if (vm.type === 'project') projectId = vm.id;
                    if (vm.type === 'issue') {
                        promise = vm.$store.dispatch("Issue/show/getInfos", vm.id);
                    };
                }
                promise.then((data: any) => {
                    if (vm.bookmark) return vm.loadBookmark();
                    if (vm.type === 'issue') projectId = data.project_id;
                    if (projectId) {
                        selectedProject = vm.projects.find(project => project.id == projectId);
                    }
                    if (vm.projects.length === 1) {
                        selectedProject = vm.projects[0];
                    }
                    if (!selectedProject) return;
                    vm.selectedProject = selectedProject;
                    vm.selectProject();
                });
            });
        });
    },
};
