
















import { mapGetters } from "vuex";
import { elementOffsetCoords } from "../tree-helpers";

export default {
  name: "TreeNodeVersion",
  props: ["cell"],
  data() {
    return {
      versionId: null,
      originalVersionId: null,
      submitting: false,
    };
  },
  computed: {
    // @ts-ignore
    ...mapGetters({
      versions: "Issue/list/versions"
    }),
    style() {
      const cellOffsetCoords = elementOffsetCoords(this.cell.element);
      return {
        top: `${cellOffsetCoords.top - 2}px`,
        left: `${cellOffsetCoords.left}px`,
        width: '300px'
      };
    },
    openedVersions() {
      return this.versions.filter(v => v.status === 'open');
    },
  },
  methods: {
    init() {
      this.versionId = this.originalVersionId = this.cell.issue.version_id;
    },
    submit(e) {
      if (this.versionId === this.originalVersionId) return this.close();
      if (this.submitting) return;
      this.submitting = true;
      const vm = this;
      this.$store
        .dispatch("Issue/edit/update", {
          issueId: this.cell.issue.id,
          issue: { fixed_version_id: this.versionId }
        })
        .then(() => {
          vm.$emit("submitted");
          vm.close();
        });
    },
    close(e) {
      this.$emit("close");
    }
  },
  mounted() {
    this.init();
    this.$nextTick(() => {
      this.$refs.input.focus();
    });
  }
};
