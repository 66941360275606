import Multiselect from "vue-multiselect";
import { Issue } from '@/app/types/interfaces';
import { groupTimeEntriesByResource } from '../tree-helpers';
import Modal from "@/views/modal/Modal.vue";
import Entry from "./TreeTimeMoverEntry.vue";

export default {
    name: "TreeTimeMover",
    components: {
        Multiselect,
        Modal,
        Entry,
    },
    props: ['issues', 'sourceIssue'],
    data() {
        return {
            movableResourceTimeEntries: [],
            targetIssue: null,
            allTimeEntries: [],
            selectedTimeEntries: [],
            selectedResources: [],
            originalSourceDoneRatio: null,
            originalTargetDoneRatio: null,
        };
    },
    computed: {
        otherIssues() {
            if (!this.sourceIssue) return [];
            const sourceIssue: Issue = this.sourceIssue;
            return this.issues.filter(issue => {
                const data: Issue = issue.data;
                return sourceIssue.version_id === data.version_id
                    && sourceIssue !== data
                    && !data.children_nb
                    ;
            });
        },
        sourceDoneRatio() {
            return this.sourceIssue.done_ratio;
        },
        targetDoneRatio() {
            return this.targetIssue?.data?.done_ratio;
        },
    },
    methods: {
        init() {
            this.originalSourceDoneRatio = this.sourceDoneRatio;
            return this.$store.dispatch('TimeEntry/list/getForIssue', {
                issueId: this.sourceIssue.id,
                passed: 2
            }).then(timeEntries => {
                this.movableResourceTimeEntries = groupTimeEntriesByResource(timeEntries, 18);
                // this.selectedResources = this.movableResourceTimeEntries.map(v => v.id);
                // this.allTimeEntries = this.selectedTimeEntries = timeEntries;
                this.allTimeEntries = timeEntries;
            });
        },
        toggleResourceTimeEntries(e, resource) {
            const resourceEntries = this.allTimeEntries.filter(entry => entry.resource_id === resource.id);
            if (e.target.checked) {
                this.selectedTimeEntries = this.selectedTimeEntries
                    .concat(resourceEntries)
                    .filter((v, i, a) => a.indexOf(v) === i);
            } else {
                this.selectedTimeEntries = this.selectedTimeEntries
                    .filter(entry => !resourceEntries.includes(entry));
            }
        },
        toggleAllTimeEntries(e) {
            if (e.target.checked) {
                this.selectedTimeEntries = this.allTimeEntries;
                this.selectedResources = this.movableResourceTimeEntries.map(v => v.id);
            } else {
                this.selectedTimeEntries = [];
                this.selectedResources = [];
            }
        },
        moveTimeEntries() {
            if (!this.targetIssue) return;
            const timeEntryIds = this.selectedTimeEntries.map(v => v.id);
            if (!timeEntryIds.length) return;
            if (!window.confirm(`Êtes-vous sûr de vouloir déplacer le temps saisi vers la tâche "${this.targetIssue.name}"`)) return;
            const sourceIssueId = this.sourceIssue.id;
            const targetIssueId = this.targetIssue.id;
            let promises = [];
            promises.push(this.$store.dispatch('TimeEntry/list/move', { fromIssueId: sourceIssueId, toIssueId: targetIssueId, timeEntryIds }));
            if (this.sourceDoneRatio !== this.originalSourceDoneRatio) {
                promises.push(this.$store.dispatch('Issue/edit/update', { issueId: sourceIssueId, issue: { done_ratio: this.sourceDoneRatio } }));
            }
            if (this.targetDoneRatio !== this.originalTargetDoneRatio) {
                promises.push(this.$store.dispatch('Issue/edit/update', { issueId: targetIssueId, issue: { done_ratio: this.targetDoneRatio } }));
            }
            Promise.all(promises).then(() => {
                this.$emit('refresh');
                this.$emit('close');
            });
        },
        dismiss() {
            this.sourceIssue.done_ratio = this.originalSourceDoneRatio;
            if (this.targetIssue) {
                this.targetIssue.data.done_ratio = this.originalTargetDoneRatio;
            }
            this.$emit('close');
        }
    },
    mounted() {
        this.init();
    }
}
