













import { elementOffsetCoords } from "../tree-helpers";

export default {
  name: "TreeNodeComment",
  props: ["cell"],
  data() {
    return {
      comment: null,
      originalComment: null,
      submitting: false,
    };
  },
  computed: {
    style() {
      const cellOffsetCoords = elementOffsetCoords(this.cell.element);
      return {
        top: `${cellOffsetCoords.bottom}px`,
        left: `${cellOffsetCoords.left + 30}px`,
        width: `${Math.max(cellOffsetCoords.width, 300)}px`,
        height: '100px',
      };
    },
  },
  methods: {
    init() {
      this.comment = this.cell.issue.comment;
      this.originalComment = this.comment;
    },
    submit() {
      if ((this.originalComment || '') === (this.comment || '')) return this.close();
      if (this.submitting) return;
      this.submitting = true;
      const vm = this;
      this.$store.dispatch("Issue/edit/setExtra", {
        issueId: this.cell.issue.id,
        issue: {comment: this.comment}
      }).then(() => {
        vm.$emit("submitted", this.comment);
        vm.close();
      });
    },
    close(e) {
      this.$emit("close");
    }
  },
  mounted() {
    this.init();
    this.$nextTick(() => {
      this.$refs.input.focus();
      this.$refs.input.select();
    });
  }
};
