import { mapGetters } from 'vuex';
import marked from "marked";
import Multiselect from "vue-multiselect";
import dayjs from "dayjs";
import * as Redmine from '@/config/redmine-constants';
import { getIssueLink } from "@/app/helpers";
import { formatK, getDPPe } from '@/views/tree/tree-helpers';
import Modal from "@/views/modal/Modal.vue";
import IconExternalLink from "@/icons/external-link-square-alt-solid.svg";
import IconTree from "@/icons/sitemap-solid.svg";
import IconDevops from "@/icons/azure-devops.svg";
import IconOpener from "@/icons/calendar-plus-solid.svg";
import IconRename from "@/icons/pen-solid.svg";
import IssueEstimatedPassed from "@/views/time-modal/IssueEstimatedPassed.vue";
import IssueStatus from "@/views/tree/node/TreeNodeStatus.vue";
import IssuePriority from "@/views/tree/node/TreeNodePriority.vue";
import IssueAssign from "@/views/tree/node/TreeNodeAssign.vue";
import IssueRename from "@/views/tree/node/TreeNodeRename.vue";

marked.setOptions({
    breaks: true,
});

export default {
    name: "IssueModal",
    components: {
        Modal,
        Multiselect,
        IconExternalLink,
        IconTree,
        IconDevops,
        IconOpener,
        IconRename,
        IssueEstimatedPassed,
        IssueStatus,
        IssuePriority,
        IssueAssign,
        IssueRename,
    },
    props: {
        issueId: null,
    },
    data() {
        return {
            issue: null,
            issueDetail: null,
            issueToRename: null,
            issueToStatus: null,
            issueToPriority: null,
            issueToAssign: null,
            issueToParent: null,
            issues: [],
            issuesSearched: [],
            issueSearchTimeout: null,
            descriptionEdit: false,
            descriptionNew: null,
            descriptionPreview: false,
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            resourcesAll: "Resource/list/resources",
            // user: "Resource/auth/user",
        }),
        description() {
            if (!this.issueDetail) return;
            const description = this.descriptionNew || this.issueDetail.description || '';
            return marked(description);
        },
        priority() {
            if (!this.issueDetail) return;
            const priority = Redmine.ISSUE_PRIORITIES.find(priority => priority.id === this.issueDetail.priority_id);
            return priority.devops + ' - ' + priority.redmine;
        },
        redmineLink() {
            if (!this.issueDetail) return;
            return getIssueLink(this.issueDetail.id);
        },
        assignedTo() {
            if (!this.issueDetail) return;
            if (!this.issueDetail.assigned_to_id) return null;
            return this.resourcesAll.find(resource => resource.id === this.issueDetail.assigned_to_id);
        },
        doneRatio() {
            if (!this.issue) return;
            return this.issue.agg_done_ratio;
        },
        dppeAble() {
            if (!this.issueDetail) return false;
            return Redmine.DPPEABLE_VERSION_TYPE.includes(this.issueDetail.version_type);
        },
        dppe() {
            if (!this.issue) return;
            return getDPPe(this.issue);
        },
        dppeCell() {
            if (!this.issue) return;
            return formatK(this.dppe);
        },
        createdSince() {
            if (!this.issueDetail) return;
            const createdOn = dayjs(this.issueDetail.created_on);
            // Working around a weird behavior with unit tests
            return typeof createdOn.fromNow === 'function' ? createdOn.fromNow() : '';
        },
        editable() {
            if (!this.issueDetail || !this.issue) return false;
            // if (this.user.admin) return true;
            // const projects = this.$store.getters["Project/list/projects"];
            // const selectedProject = projects.find(project => project.id === this.issue.project_id);
            // if (!userIsCPDPForProject(this.user, selectedProject)) return false;
            return !this.issueDetail.devops_id;
        },
        isClosed() {
            if (!this.issue) return false;
            return this.issue.status_id === Redmine.ISSUE_STATUS_CLOSED;
        },
        isRemoved() {
            if (!this.issue) return false;
            return this.issue.status_id === Redmine.ISSUE_STATUS_REMOVED;
        },
        hasChildren() {
            return this.issue.children_nb > 0;
        },
        canEnterTime() {
            return this.issueDetail && this.issue && !this.hasChildren && !this.isClosed && !this.isRemoved && this.issue.version_open;
        },
        parent() {
            if (!this.issue) return null;
            const parentId = this.issue.parent_id;
            return parentId ? this.issues.find(issue => issue.id === parentId).subject : null;
        },
        relations() {
            if (!this.issueDetail) return [];
            return this.issueDetail.relations;
        },
        descriptionChanged() {
            return this.descriptionEdit && this.descriptionNew !== this.issueDetail.description;
        },
    },
    methods: {
        close() {
            // this.issue = null;
            // this.issueDetail = null;
            if (!this.canDismissDescriptionEdit()) return;
            this.$emit('close');
        },
        // plan() {
        //     if (!this.canDismissDescriptionEdit()) return;
        //     this.$emit('plan');
        //     this.$emit('close');
        // },
        normalizeResource(resource) {
            if (!resource) return '';
            return resource.firstname + " " + resource.lastname;
        },
        removeModalOpenClass() {
            document.body.classList.remove('modalopen');
        },

        openIssueRename() {
            if (!this.editable) return;
            this.issueToRename = {
                issue: this.issue,
            };
        },
        closeIssueRename() {
            this.issueToRename = null;
        },

        openIssueStatus() {
            if (!this.editable) return;
            this.issueToStatus = {
                issue: { id: this.issueDetail.id, statusId: this.issue.status_id },
            };
        },
        closeIssueStatus() {
            this.issueToStatus = null;
        },

        openIssuePriority() {
            if (!this.editable) return;
            this.issueToPriority = {
                id: this.issueDetail.id,
                priorityId: this.issueDetail.priority_id
            };
        },
        closeIssuePriority() {
            this.issueToPriority = null;
        },

        openIssueAssign() {
            if (!this.editable) return;
            this.issueToAssign = {
                id: this.issueDetail.id,
                assignedTo: this.issueDetail.assigned_to_id,
                projectId: this.issueDetail.project_id,
            };
        },
        closeIssueAssign() {
            this.issueToAssign = null;
        },

        openIssueParent() {
            if (!this.editable) return;
            this.issueToParent = true;
            this.$nextTick(() => this.$refs.issueParent.$el.focus());
        },
        closeIssueParent() {
            this.issueToParent = null;
            this.issueSearch = '';
        },
        searchIssue(search) {
            if (this.issueSearchTimeout) clearTimeout(this.issueSearchTimeout);
            const vm = this;
            this.issueSearchTimeout = setTimeout(function () {
                if (search.length < 3) return;
                const payload = { projectId: vm.issueDetail.project_id, search };
                vm.$store.dispatch('Issue/list/search', payload).then((issues) => {
                    vm.issuesSearched = issues.filter(issue => issue.id !== vm.issue.id);
                });
            }, 500);
        },
        unselectParent() {
            if (!window.confirm('Are you sure you want to unset the parent ?')) return;
            this.selectParent(null);
        },
        selectParent(parent) {
            const parentId = parent ? parent.id : null;
            const payload = {
                issueId: this.issueId,
                issue: {
                    parent_issue_id: parentId,
                },
            }
            this.$store.dispatch('Issue/edit/update', payload).then(() => {
                if (parent) this.issues.push(parent);
                this.issue.parent_id = parentId;
                this.$emit('update');
            });
        },

        canDismissDescriptionEdit() {
            if (!this.descriptionChanged) return true;
            return window.confirm('The description has been modified\nAre you sure you want to dismiss the modification ?');
        },
        updateDescription() {
            const payload = {
                issueId: this.issueId,
                issue: { description: this.descriptionNew },
            }
            this.$store.dispatch('Issue/edit/update', payload).then(() => {
                this.issueDetail.description = this.descriptionNew;
                this.descriptionNew = null;
                this.descriptionEdit = false;
            });
        },

        loadIssue(issueId) {
            this.$store.dispatch('Issue/show/getInfos', issueId).then((issue) => {
                this.issueDetail = issue;
            });
            this.$store.dispatch('Issue/list/getList', { issueIds: [issueId] }).then((issues) => {
                this.issues = issues;
                this.issue = issues.find(issue => issue.id === issueId);
            });
        },
        update() {
            this.$emit('update');
            this.loadIssue(this.issueId);
        },

        copyEntryLink(e) {
            if (!navigator.clipboard) return;
            e.preventDefault();
            navigator.clipboard.writeText(this.$refs.entryLink.$el.href);
            const textElm = this.$refs.entryLinkText;
            const previousText = textElm.innerHTML;
            textElm.innerHTML = 'Copied !';
            setTimeout(() => {
                textElm.innerHTML = previousText;
            }, 1000);
            return false;
        },
    },
    mounted() {
        this.loadIssue(this.issueId);
    },
};
