






export default {
  name: "FormError",
  props: ["errors"],
};
