




















import Multiselect from "vue-multiselect";

export default {
  name: "VersionSelect",
  components: { Multiselect },
  props: {
    value: {},
    versions: {
      default: [],
    },
    id: {
      default: 'selected_version',
    },
    allowEmpty: {
      default: true
    },
    multiple: {
      default: false
    },
    oneliner: {
      default: false
    },
    placeholder: {
      default: 'Version'
    },
    disabled: {
      default: false
    },
  },
  computed: {
  },
  data() {
    return {
    };
  },
  methods: {
    input(newValue) {
      this.$emit("input", newValue);
    },
    open(e) {
      this.$emit("open", e);
    },
  },
  mounted() {
  },
};
