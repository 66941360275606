







































































import { mapGetters } from "vuex";
import { delegate as tippyDelegate } from "tippy.js";

export default {
  name: "TreeTop",
  props: {
    listMode: {
      default: false,
    },
    advanced: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      cia: "App/colSet/cia",
      noColSet: "App/colSet/noColSet",
    }),
  },
  methods: {
    tipTop() {
      tippyDelegate(this.$refs.treeTop, {
        target: ".top_init_sold",
        multiple: true,
        content: "Vendu Initial de la Proposition Commerciale",
      });
      tippyDelegate(this.$refs.treeTop, {
        target: ".top_sold",
        multiple: true,
        content:
          "Jours payés par le client.<br />Les commandés sont en PECC<br />Le chiffre de gauche est la somme des temps de la tâche et de ses sous-tâches (agrégé), le chiffre de droite uniquement le temps sur la tâche elle-même",
      });
      tippyDelegate(this.$refs.treeTop, {
        target: ".top_estimated",
        multiple: true,
        content:
          "Le chiffre de gauche est la somme des temps de la tâche et de ses sous-tâches (agrégé), le chiffre de droite uniquement le temps sur la tâche elle-même",
      });
      tippyDelegate(this.$refs.treeTop, {
        target: ".top_pecc",
        multiple: true,
        content:
          "Jours commandés par le client mais non encore payés. Les payés sont en Vendu",
      });
      tippyDelegate(this.$refs.treeTop, {
        target: ".top_pecm",
        multiple: true,
        content: "Pris en charge MASAO",
      });
      tippyDelegate(this.$refs.treeTop, {
        target: ".top_raa",
        multiple: true,
        content: "Reste à Affecter<br /><i>Estimé - Vendu - PECC - PECM</i>",
      });
      tippyDelegate(this.$refs.treeTop, {
        target: ".top_s",
        multiple: true,
        content: "Indicateur SWIS",
      });
      tippyDelegate(this.$refs.treeTop, {
        target: ".top_drcc",
        multiple: true,
        content: "Dernière date Réponse Client Copil Coproj",
      });
      tippyDelegate(this.$refs.treeTop, {
        target: ".top_raf",
        multiple: true,
        content: "Reste à Faire<br /><i>Réalisé / Progression - Réalisé</i>",
      });
      tippyDelegate(this.$refs.treeTop, {
        target: ".top_dpp",
        multiple: true,
        content:
          "Dépassement Projet Potentiel<br /><i>Réalisé / Progression - Vendu - PECC - PECM</i>",
      });
      tippyDelegate(this.$refs.treeTop, {
        target: ".top_dad",
        multiple: true,
        content: "Dépassement à Date<br /><i>Réalisé - Progression * Vendu</i>",
      });
      tippyDelegate(this.$refs.treeTop, {
        target: ".top_masao",
        multiple: true,
        content: "Indicateur Masao",
      });
    },
  },
  mounted() {
    this.tipTop();
  },
};
