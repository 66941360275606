






























export default {
  name: "TreeVersionChangerLine",
  props: ["value", "sourceProfile", "targetProfiles"],
  computed: {
    redmine() {
      return !this.sourceProfile.profile_id;
    },
    tieNb() {
      return this.sourceProfile.tie_nb;
    },
    discrep() {
      return this.sourceProfile.discrep === '1';
    },
  },
};
