



















import { mapGetters } from "vuex";
import { filterResourceByProject } from "@/app/helpers";

export default {
  name: "TreeNodeAssign",
  props: ["issue"],
  data() {
    return {
      assignedTo: null,
      originalAssignedTo: null,
      submitting: false,
    };
  },
  computed: {
    // @ts-ignore
    ...mapGetters({
      resourcesAll: "Resource/list/resources",
      projects: "Project/list/projects",
    }),
    project() {
      return this.projects.find((project) => this.issue.projectId === project.id);
    },
    resources() {
      return this.resourcesAll.filter((resource) =>
        filterResourceByProject(resource, this.project)
      );
    },
    size() {
      return Math.min(this.resources.length, 10);
    },
    style() {
      return { top: "-4px", left: "-4px" };
    },
  },
  methods: {
    init() {
      this.assignedTo = this.issue.assignedTo;
      this.originalAssignedTo = this.assignedTo;
    },
    submit() {
      if (this.assignedTo === this.originalAssignedTo) return this.close();
      if (this.submitting) return;
      this.submitting = true;
      const vm = this;
      this.$store
        .dispatch("Issue/edit/update", {
          issueId: this.issue.id,
          issue: { assigned_to_id: this.assignedTo ? this.assignedTo : "" },
        })
        .then(() => {
          this.submitting = false;
          vm.$emit("submitted");
          vm.close();
        });
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    this.init();
    this.$nextTick(() => {
      this.$refs.input.focus();
    });
  },
};
