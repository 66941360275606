















import { elementOffsetCoords } from "../tree-helpers";

export default {
  name: "TreeCellEdit",
  props: ["cell"],
  data() {
    return {
      originalValue: null,
      value: null,
      submitting: false,
    };
  },
  computed: {
    style() {
      const cellOffsetCoords = elementOffsetCoords(this.cell.element);
      return {
        top: `${cellOffsetCoords.top - 2}px`,
        left: `${cellOffsetCoords.left}px`,
        width: `100px`
      };
    },
    property() {
      return this.cell.dataset["property"];
    },
    type() {
      return this.cell.dataset["type"];
    },
  },
  watch: {
    cell() {
      // This fixes the bug when switching between cells of the same row
      this.init();
    }
  },
  methods: {
    init() {
      this.value = this.cell.issue[this.property] / 8;
      this.originalValue = this.value;
    },
    submit() {
      if (parseFloat(this.originalValue) === parseFloat(this.value))
        return this.close();
      if (this.submitting) return;
      this.submitting = true;
      let promise = null;
      let payload = { issueId: this.cell.issue.id, issue: {} };
      if (this.type === "extra") {
        const property = this.property.replace(/_(\w)/g, g => g[1].toUpperCase())
        payload.issue[property] = this.value * 8;
        promise = this.$store.dispatch("Issue/edit/setExtra", payload);
      } else {
        payload.issue = { [this.property]: (this.value || 0) * 8 };
        promise = this.$store.dispatch("Issue/edit/update", payload);
      }
      const vm = this;
      promise.then(() => {
        vm.$emit("submitted");
        vm.close();
      });
    },
    close(e) {
      this.$emit("close");
    }
  },
  mounted() {
    this.init();
    this.$nextTick(() => {
      this.$refs.input.focus();
      this.$refs.input.select();
    });
  }
};
