import { mapGetters } from "vuex";
import { getCustomFieldValue } from "@/app/helpers";
import * as Redmine from "@/config/redmine-constants";


function getVerFromVersion(version) {
    const ver = {
        name: '',
        description: null,
        type: null,
        bdc: null,
        open: true,
    };
    if (version) {
        ver.name = version.name;
        ver.description = version.description;
        ver.open = version.status === 'open';
        ver.type = getCustomFieldValue(version, Redmine.CUSTOM_FIELD_VERSION_TYPE);
        ver.bdc = getCustomFieldValue(version, Redmine.CUSTOM_FIELD_VERSION_BDC);
    }
    return ver;
}


export default {
    name: 'VersionModalForm',
    props: {
        version: {},
        projectId: {
            type: Number,
        },
    },
    data() {
        return this.getDefaultData();
    },
    computed: {
        ...mapGetters({
            types: 'Enumeration/customFields/versionTypes',
        }),
    },
    methods: {
        getDefaultData() {
            return {
                errors: {
                    name: false,
                    type: false,
                },
                ver: getVerFromVersion(this.version),
            };
        },
        validate() {
            const requiredFields = ['name', 'type'];
            requiredFields.forEach(requiredField => {
                this.errors[requiredField] = !this.ver[requiredField];
            });
            return !Object.values(this.errors).find(error => error);
        },
        submit() {
            if (!this.validate()) return;
            const version = { ...this.ver };
            version.status = version.open ? 'open' : 'closed';
            delete version.open;
            const id = this.version ? this.version.id : this.projectId;
            const action = 'Project/version/' + (this.version ? 'update' : 'create');
            this.$store.dispatch(action, { id, version }).then(() => { this.$emit('submit'); });
        },
    },
    mounted() {
        this.$store.dispatch('Enumeration/customFields/getList');
        this.$refs.name.focus();
    },
};
