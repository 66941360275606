import { mapGetters } from 'vuex';
import IconAdd from '@/icons/plus-square-solid.svg';
import Modal from '@/views/modal/Modal.vue';
import MemberModalLine from './MemberModalLine.vue';
import ResourceSelect from "@/views/common/ResourceSelect.vue";

export default {
    name: 'MemberModal',
    components: {
        Modal,
        MemberModalLine,
        IconAdd,
        ResourceSelect,
    },
    props: {
        project: {}
    },
    data() {
        return {
            members: [],
            newMember: {
                user: null,
                role: null,
            },
        };
    },
    computed: {
        ...mapGetters({
            roles: 'Enumeration/list/roles',
            resources: "Resource/list/resourcesAll",
        }),
    },
    methods: {
        close() {
            this.$emit('close');
        },
        add() {
            if (!this.newMember.user || !this.newMember.role) return;
            const payload = {
                projectId: this.project.id,
                data: {
                    user_id: this.newMember.user.id,
                    role_id: this.newMember.role.id,
                }
            };
            this.$store.dispatch('Project/member/add', payload).then(() => {
                this.newMember.user = null;
                this.newMember.role = null;
                this.refresh();
            });
        },
        edit(member) {
            this.newMember.user = this.resources.find(res => res.id === member.user_id);
            this.newMember.role = this.roles.find(rol => rol.id === member.role_id);
        },
        remove(member) {
            if (!window.confirm('Are you sure you want to remove this member ?')) return;
            this.$store.dispatch('Project/member/remove', member.id).then(() => { this.refresh() });
        },
        refresh() {
            this.$store.dispatch('Project/member/list', this.project.id).then(members => {
                this.members = members;
            });
        },
    },
    mounted() {
        this.refresh();
        this.$store.dispatch('Enumeration/list/getRoles');
    },
};
