
















import { elementOffsetCoords } from "../tree-helpers";
import { ISSUE_STATUSES_ID_LABEL } from "@/config/redmine-constants";

export default {
  name: "TreeNodeStatus",
  props: ["cell"],
  data() {
    return {
      statusId: null,
      originalStatusId: null,
      submitting: false,
      statuses: ISSUE_STATUSES_ID_LABEL,
    };
  },
  computed: {
    style() {
      const style = { top: '-4px', left: '-4px', width: '100px' };
      if (this.cell.element) {
        const cellOffsetCoords = elementOffsetCoords(this.cell.element);
        style.top = `${cellOffsetCoords.top - 2}px`;
        style.left = `${cellOffsetCoords.left}px`;
      }
      return style;
    }
  },
  methods: {
    init() {
      this.statusId = this.cell.issue.statusId;
      this.originalStatusId = this.statusId;
    },
    submit(e) {
      if (this.statusId === this.originalStatusId) return this.close();
      if (this.submitting) return;
      this.submitting = true;
      const vm = this;
      this.$store
        .dispatch("Issue/edit/update", {
          issueId: this.cell.issue.id,
          issue: { status_id: this.statusId }
        })
        .then(() => {
          vm.$emit("submitted");
          vm.close();
        });
    },
    close(e) {
      this.$emit("close");
    }
  },
  mounted() {
    this.init();
    this.$nextTick(() => {
      this.$refs.input.focus();
    });
  }
};
