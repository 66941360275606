<style lang="scss">
#colset_modal {
  .list-group-item {
    padding-left: 2.5em;
  }
}
</style>

<template>
  <modal @close="close" id="colset_modal">
    <template #header>
      Colonnes à afficher
      <button class="btn close" @click="close">&times;</button>
    </template>

    <div class="row">
      <div class="col">
        <ul class="list-group">
          <Checkbox
            :class="[
              'list-group-item',
              'list-group-item-action',
              { active: colSet.includes(col.id), disabled: col.readonly },
            ]"
            v-for="col in leftCols"
            :key="col.id"
            :label="col.label"
            v-model="colSet"
            :value="col.id"
          />
        </ul>
      </div>
      <div class="col">
        <ul class="list-group">
          <Checkbox
            :class="[
              'list-group-item',
              'list-group-item-action',
              { active: colSet.includes(col.id), disabled: col.readonly },
            ]"
            v-for="col in rightCols"
            :key="col.id"
            :label="col.label"
            v-model="colSet"
            :value="col.id"
          />
        </ul>
      </div>
    </div>

    <template #footer>
      <button class="btn btn-secondary ml-auto" @click="close">Fermer</button>
      <button class="btn btn-primary ml-1" @click="submit">Valider</button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/views/modal/Modal.vue";
import { TREE_COLS } from '@/config/constants';

export default {
  name: "ColSetModal",
  components: {
    Modal,
  },
  props: {
    cols: {},
  },
  data() {
    return {
      colSet: this.cols,
    };
  },
  computed: {
    half() {
      return Math.ceil(TREE_COLS.length / 2);
    },
    leftCols() {
      return TREE_COLS.slice(0, this.half);
    },
    rightCols() {
      return TREE_COLS.slice(-this.half);
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit", this.colSet);
      this.$emit("close");
    },
  },
};
</script>
