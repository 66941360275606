













import { elementOffsetCoords } from "../tree-helpers";

export default {
  name: "TreeNodeRename",
  props: ["cell"],
  data() {
    return {
      subject: "",
      originalSubject: "",
      submitting: false,
    };
  },
  computed: {
    style() {
      if (!this.cell.element) return {};
      const cellOffsetCoords = elementOffsetCoords(this.cell.element);
      return {
        top: `${cellOffsetCoords.top - 2}px`,
        left: `${cellOffsetCoords.left + 24}px`,
        width: "300px",
      };
    },
  },
  methods: {
    init() {
      this.originalSubject = this.subject = this.cell.issue.subject;
    },
    validate() {
      if (!this.subject) return false;
      return this.subject.length > 2;
    },
    submit() {
      if (this.subject === this.originalSubject) return this.close();
      if (!this.validate()) return;
      if (this.submitting) return;
      this.submitting = true;
      const vm = this;
      this.$store
        .dispatch("Issue/edit/update", {
          issueId: this.cell.issue.id,
          issue: { subject: this.subject },
        })
        .then(() => {
          this.$gtag.event("Rename Task", {
            event_category: "SPA",
          });
          vm.$emit("submitted");
          vm.close();
        });
    },
    blur() {
      if (!this.validate()) return this.close();
      this.submit();
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    this.init();
    this.$nextTick(() => {
      this.$refs.input.focus();
      this.$refs.input.select();
    });
  },
};
